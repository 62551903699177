<template>
    <Home />
</template>

<script>

import Home from '../components/home.vue'


export default {
    name: 'BNB',
    data(){
        return{
            
        }
    },

    components: {
        Home
    },
}
</script>